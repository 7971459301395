/* --------------- */
/* ----- Finisherschutz ----- */
/* --------------- */


// Fonts

/* poppins-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins Extralight';
  font-style: normal;
  font-weight: 200;
  src: url('./webfont/din-next/Fonts/poppins-v20-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: 300;
  src: url('./webfont/din-next/Fonts/poppins-v20-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./webfont/din-next/Fonts/poppins-v20-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('./webfont/din-next/Fonts/poppins-v20-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./webfont/din-next/Fonts/poppins-v20-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

$font-extralight: "Poppins Extralight";
$font-light: "Poppins Light";
$font-regular: "Poppins Regular";
$font-semibold: "Poppins SemiBold";
$font-bold: "Poppins Bold";

$font-black: "Poppins Bold";


 

// Custom Colors
$color-purple: rgb(114, 66, 147); /* #724293 */
$color-grey: rgb(247, 247, 247); /* #F7F7F7 */
$color-grey-input: #d9d9d9; /* #F7F7F7 */

$color-orange: rgb(245, 156, 0); /* #F59C00 */
$color-grey-44: rgb(112, 112, 112); /* #707070 */
$color-grey-59: rgb(150, 150, 150); /* #969696 */
$color-grey-85: rgb(216, 216, 216); /* #D8D8D8 */
$color-grey-96: rgb(244, 244, 244); /* #F4F4F4 */


// Cusom Variables

$gap:40px;



// Easing
@mixin ease {
  transition: all 0.15s ease-in-out;
}




/* >>>>>>>>>>>>>>>>>>>> BOOTSTRAP */


// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

/* IMPORTANT: make sure to have all bootstrap-variables you want to use in the overrides here inside, before bootstrap.scss is imported */

// Color system

$black: #000000;
$white: #ffffff;
$primary: $color-purple;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $black;

// Links
//
// Style anchor elements.

$link-color: $primary;
$link-decoration: none;
$link-hover-decoration: none;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1.5rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  xxs: 444,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px, /* additional */
  xxxxl: 1920px, /* additional */
  xxxxxl: 2560px, /* additional */
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px,
  xxxl: 1440px, /* additional */
  xxxxl: 1640px, /* additional */
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           16px;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 					1px;
$border-color: 					$color-grey;

$border-radius:               	0;
$border-radius-lg:            	0;
$border-radius-sm:            	0;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: $font-regular, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$font-weight-normal: 300; /* = light */
$font-weight-normal: 400; /* = regular */
$font-weight-semibold: 600; /* semibold */
$font-weight-bold: 700; /* = bold */
$font-weight-black: 900; /* = black */

$font-weight-base: $font-weight-normal;
$line-height-base: 1.75;

$h1-font-size: 40px;
$h2-font-size: 30px; 
$h3-font-size: 18px;
$h4-font-size: 16px;
$h5-font-size: 14px;
$h6-font-size: 12px;

$headings-margin-bottom:	1.5rem;
$headings-font-family: 		$font-bold;
$headings-font-weight: 		$font-weight-normal;
$headings-line-height: 		1.25;
$headings-color: 			    $color-purple;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 50px,
  2: 30px,
  3: 26px,
  4: 20px,
  5: 18px,
  6: 16px
) !default;

$display-font-family: $font-light;
$display-font-weight: normal;

$hr-border-color:         $color-grey; 
$hr-border-width:         1px;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .25rem !default;
$table-cell-padding-sm:       .1rem !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.5rem;
$input-btn-padding-x:         2.5rem;

$input-btn-padding-y-sm:      0.25rem;
$input-btn-padding-x-sm:      2.0rem;

$input-btn-padding-y-lg:      0.75rem;
$input-btn-padding-x-lg:      3.5rem;

$input-btn-focus-width:       0;

// Buttons
// 
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;

$input-btn-font-size:         18px;
$btn-font-weight:             $font-weight-normal;

// Forms

$label-margin-bottom:                   .5rem;

$input-font-family:                     $font-light;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-normal;

$input-bg:                              $white;

$input-color:                           $black;
$input-border-color:                    $color-grey-input;

$input-focus-bg:                        $white;
$input-focus-border-color:              $color-grey;
$input-focus-color:                     $black;

$input-placeholder-color:               #6c757d;

$form-check-input-gutter:               1.50rem;
$custom-control-spacer-x:               2rem;
/* $form-check-input-margin-y:             0.5rem; */
/* $form-check-input-margin-x:             0.5rem; */

$form-check-inline-margin-x:            0.75rem;
$form-check-inline-input-margin-x:      0.75rem;

$custom-control-indicator-size:         1.25rem;
$custom-control-indicator-border-color: $color-grey;

$custom-select-font-size:               16px;

$custom-checkbox-indicator-border-radius:       0;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-border-radius:            10px;

// Pagination

$pagination-padding-y:              0.31rem;
$pagination-padding-x:              0.5rem;

$pagination-bg:                     transparent;
$pagination-border-color:           $color-grey;

$pagination-hover-bg:               transparent;
$pagination-hover-border-color:     $color-grey;

// Breadcrumbs

$breadcrumb-font-size:              16px;

$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           20px;

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $color-grey;
$breadcrumb-active-color:           $color-grey;
$breadcrumb-divider:                quote("/") !default;

$breadcrumb-border-radius:          0;

// Badges

$badge-font-size:                   14px;
$badge-font-weight:                 $font-weight-normal;
$badge-padding-y:                   0.25rem;
$badge-padding-x:                   0.5rem;

// Background



// END Variables override




// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";

@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/badge";
/* @import "../node_modules/bootstrap/scss/breadcrumb"; */
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/buttons";
/* @import "../node_modules/bootstrap/scss/card"; */
/* @import "../node_modules/bootstrap/scss/carousel"; */
/* @import "../node_modules/bootstrap/scss/close"; */
/* @import "../node_modules/bootstrap/scss/dropdown"; */
@import "../node_modules/bootstrap/scss/forms";
/* @import "../node_modules/bootstrap/scss/functions"; */
/* @import "../node_modules/bootstrap/scss/images"; */
/* @import "../node_modules/bootstrap/scss/list-group"; */
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
/* @import "../node_modules/bootstrap/scss/offcanvas"; */
@import "../node_modules/bootstrap/scss/pagination";
/* @import "../node_modules/bootstrap/scss/placeholders"; */
/* @import "../node_modules/bootstrap/scss/popover"; */
/* @import "../node_modules/bootstrap/scss/progress"; */
/* @import "../node_modules/bootstrap/scss/spinners"; */
@import "../node_modules/bootstrap/scss/tables";
/* @import "../node_modules/bootstrap/scss/toasts"; */
/* @import "../node_modules/bootstrap/scss/tooltip"; */
@import "../node_modules/bootstrap/scss/transitions";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";


/* BOOTSTRAP <<<<<<<<<<<<<<<<<<<< */




// modules
@import "./modules.scss";

// global
@import "./global.scss";